#sidebar .drawer-heading {
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
}
#sidebar .sidebar-toggler {
  position: fixed;
  left: 10px;
  top: 10px;
}

#sidebar .content-wrapper {
  transition: 0.5s margin-left;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-drawer-wrapper-body {
  border-right: 1px solid #eee;
}

.ant-table-thead > tr > th {
  background: #1d8ffc !important;
  color: white;
  font-weight: bold;
}

.ant-menu-title-content {
  padding-left: 32px !important;
}

.ant-drawer-body {
  padding: 0px !important;
}

ul.ant-menu {
  background: transparent !important;
}

.ant-menu .ant-menu-item {
  color: #4d4d4d99 !important;
  padding-left: 0 !important;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 35px !important;
  margin-left: 0 !important;
  font-weight: 600;
}

li.ant-menu-item-selected {
  background: #ffffff !important;
  color: #e0ad10 !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

li.ant-menu-item-selected a {
  color: #e0ad10 !important;
}

.ant-menu-item:not(.ant-menu-item-disabled):focus-visible {
  outline: none;
}
