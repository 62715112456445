@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  user-select: none;
  box-sizing: border-box;
}

/* ANT Button */
.ant-btn-primary {
  background-color: #4096ff !important;
}

/* SELECT INPUT ANT */
#select-input .ant-select-selector {
  height: 100%;
  border-radius: 16px !important;
  padding-left: 20px !important;
}

#select-input .ant-select-selection-item {
  display: flex;
  align-self: center;
  font-size: 18px;
}
#select-input .ant-select-selection-placeholder {
  display: flex;
  align-self: center;
  color: #818181;
  font-weight: 600;
  font-size: 18px;
}

.ant-select-selection-search input {
  height: 100% !important;
  padding-left: 10px !important;
}

.ant-select-arrow {
  right: 20px !important;
}

.ant-select-arrow svg {
  width: 20px !important;
  height: 20px !important;
}

/* Stepper */
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #ffcc33 !important;
  border-color: #ffcc33 !important;
}
.ant-steps-item-icon .ant-steps-icon {
  top: -3px !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #ffcc33 !important;
  border-color: #ffcc33 !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #ffcc33 !important;
  height: 3px !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #ececec !important;
  height: 3px !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #ececec !important;
  height: 3px !important;
}

/* Radio Button */

.ant-radio-wrapper {
  margin-right: 30px !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #ffcc33 !important;
  border-color: #ffcc33 !important;
}

.ant-radio-inner {
  height: 25px !important;
  width: 25px !important;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #ffcc33 !important;
}

.edit-modal .ant-modal-content {
  border-radius: 32px !important;
  padding: 0 !important;
}



.radio-group .ant-radio {
  margin-right: 12px !important;
}
